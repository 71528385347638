import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './index.scss';
import reportWebVitals from './reportWebVitals';
import Dashboard from './pages/dashboard';
// import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <BrowserRouter>
      <Switch>

        <Route exact path="/" component={() => {
          window.location.href = `${process.env.REACT_APP_cognito_url}`;
          return null;
        }} />

        <Route exact path="/dashboard" >
           <Dashboard /> 
        </Route>

      </Switch>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
