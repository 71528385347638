import jwt from 'jwt-decode';

export const getDecodedAccessToken = (token) => {
    try {
        return jwt(token);
    } catch (Error) {
        return null;
    }
};

export const isAuthenticated = () => {
    if (localStorage.getItem('access_token') != null) {
        return true;
    } else {
        return false;
    }
};

export const getCurrentLoggedInUser = () => {
    return localStorage.getItem('currentUser');
};

export const getAccessToken = () => {
    return localStorage.getItem('access_token');
};

export const getIdToken = () => {
    return localStorage.getItem('id_token');
};



